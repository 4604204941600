<nz-layout class="arena-edit-event">
  <nz-content>
    <div nz-row [nzGutter]="[8, 8]">
      <div nz-col nzSpan="6" class="col-left">
        <nz-card nzHoverable style="width: 100%" [nzCover]="coverTemplate">
          <nz-card-meta [nzTitle]="fightEvent.name" [nzDescription]="fightEventDescriptionTemplate"></nz-card-meta>
        </nz-card>
        <ng-template #coverTemplate>
          <div class='arena-event-photo-container'>
            <img loading="lazy" alt="example" (click)="fileSelect.click()" [src]="previewPlaceholder" />
            <div class='arena-event-photo-actions'>
              <div class="arena-event-photo-actions-list">
                <button nz-button nzType="primary" nz-tooltip="Zoom" (click)="onZoomPhotoClick()"><i nz-icon nzType="zoom-in"></i></button>
                <button nz-button nzType="primary" nz-tooltip="Edit" (click)="fileSelect.click()"><i nz-icon nzType="edit"></i></button>
                <button nz-button nzType="primary" nz-tooltip="Delete" (click)="onDeletePhotoClick()"><i nz-icon nzType="delete"></i></button>
              </div>
            </div>
          </div>
          <app-image-cropper [imageChangedEvent]='imageChangedEvent'
                             (onUpload)='onPhotoUpload($event)'
                             (onClose)='onCloseCropper()'
                             [inputFile] = fileSelect
                             [isModalVisible]='!!(isModalVisible$ | async)'>
          </app-image-cropper>
          <input style="display: none" #fileSelect type="file" (change)="onPhotoChange($event)" accept=".jpg, .jpeg, .png">
        </ng-template>
        <ng-template #fightEventDescriptionTemplate>
          <p>Fights: <b>{{ countFights }}</b></p>
          <input #nameInput nz-input placeholder="Arena name" [(ngModel)]="fightEvent.name" class="input-field" nzSize="default" />
          <nz-date-picker
            nzShowTime
            nzFormat="yyyy-MM-dd HH:mm:ss"
            ngModel
            nzSize="default"
            style="width: 100%"
            class="input-field"
            [(ngModel)]="fromDate"
            (ngModelChange)="onFromDateChange($event)"
          ></nz-date-picker>
          <nz-collapse>
            <nz-collapse-panel [nzHeader]="fightEvent.isPPV ? 'Update PPV' : 'Convert to PPV'"
            >
              <form style="margin:0;" nz-form [formGroup]="ppvValidateForm" (ngSubmit)="onSubmitPPV()">
                <nz-form-item>
                  <nz-form-label [nzSpan]="6" nzFor="price">Price</nz-form-label>
                  <nz-form-control nzErrorTip="Price is required">
                    <nz-input-group>
                      <input type="number" nz-input formControlName="price" id="price" placeholder="PPV price" />
                    </nz-input-group>
                  </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                  <nz-form-label [nzSpan]="6" nzFor="discount">Sale</nz-form-label>
                  <nz-form-control>
                    <nz-input-group>
                      <input type="number" nz-input formControlName="discount" id="discount" placeholder="PPV discount" />
                    </nz-input-group>
                  </nz-form-control>
                </nz-form-item>
                <button nz-button [disabled]="!ppvValidateForm.valid" [nzType]="'primary'">Save</button>
              </form>
            </nz-collapse-panel>
          </nz-collapse>
        </ng-template>
      </div>
      <div nz-col nzSpan="18">
        <ng-container *ngIf='eventHasVideo()'>
          <video-viewer [videoSrc]='eventVideoSrc()'></video-viewer>
        </ng-container>
        <div class="slots-list">
          <div class="time-slot-row" nz-row *ngFor="let timeSlot of fightEvent.fightSlots; let idx = index">
            <div *ngIf="!timeSlot.isEmptySlot()" nzTooltipTitle="Delete slot" nz-tooltip class="delete-slot" (click)="onDeleteSlot(timeSlot, idx)"><i nz-icon nzType="delete" nzTheme="twotone" nzTwotoneColor="#f93154"></i></div>
            <!-- LEFT -->
            <div class="time-slot-item" nz-col nzXs="2" nzSm="4" nzMd="6" nzLg="8" nzXl="10">
              <ng-container *ngIf="!timeSlot.isEmptySlotInitiator(); else emptySlotInitiator">
                <arena-event-slot-card *ngIf="timeSlot.fight"
                                       [fightEvent]="fightEvent"
                                       [slot]="timeSlot"
                                       [fighter]="timeSlot.fight.initiator"
                                       [slotType]="slotTypes.Initiator"
                ></arena-event-slot-card>
              </ng-container>
              <ng-template #emptySlotInitiator>
                <arena-event-slot-empty [slotType]="slotTypes.Initiator"
                                        (addCardClick)="onAddNewCardToSlot($event, timeSlot)"
                ></arena-event-slot-empty>
              </ng-template>
            </div>
            <!-- MIDDLE -->
            <div nz-col nzXs="4" nzSm="4" nzMd="4" nzLg="4" nzXl="4" class="middle-col">
              <div *ngIf="!timeSlot.isEmptySlot() && timeSlot.hasFight()" class='middle-col-top'>
                <ng-container *ngIf='!timeSlot.fight!.fightLink; else slotFightVideo'>
                  <ng-container *ngIf='fightEvent.isLive()'>
                    <button *ngxPermissionsOnly="[permissions.SetCurrentlyFightSlot]" (click)='onSetCurrentlyFightClick(timeSlot)' nz-button nzType="primary">Set currently fight</button>
                  </ng-container>
                  <h6>Set up video</h6>
                  <div>
                    <nz-input-group nzSearch [nzAddOnAfter]="fromTimeButton">
                      <input #inputFrom type="text" nz-input readonly />
                    </nz-input-group>
                    <ng-template #fromTimeButton>
                      <button nz-button nzType="primary" (click)='setTimeToInput(inputFrom)'>
                        <i nz-icon nzType="plus"></i>
                      </button>
                    </ng-template>
                  </div>
                  <div>
                    <nz-input-group nzSearch [nzAddOnAfter]="toTimeButton">
                      <input #inputTo type="text" nz-input readonly />
                    </nz-input-group>
                    <ng-template #toTimeButton>
                      <button nz-button nzType="primary" (click)='setTimeToInput(inputTo)'>
                        <i nz-icon nzType="plus"></i>
                      </button>
                    </ng-template>
                  </div>
                  <div>
                    <button class='btn-save-time' nz-button nzType="primary"
                            [disabled]='!parseFightInputTime(inputFrom.value) || !parseFightInputTime(inputTo.value)'
                            (click)='onSaveFightSlotTime(timeSlot, inputFrom, inputTo)'>Save</button>
                  </div>
                </ng-container>
                <ng-template #slotFightVideo>
                  <h6>Video</h6>
                  <i nzTooltipTitle="Delete" (click)='onDeleteSlotVideo(timeSlot)' nz-tooltip class='delete-slot-video' nz-icon nzType="delete" nzTheme="twotone" nzTwotoneColor="#f93154"></i>
                  <video-viewer [videoSrc]='timeSlot.fight!.fightLink'></video-viewer>
                </ng-template>
              </div>
              <div class='middle-col-bottom'>
                <div class="slot-time" nz-tooltip [nzTooltipTitle]="fightEvent.location?.tz" nzTooltipPlacement="top">{{ timeSlot.fromToTimeString(fightEvent.location?.tz ?? 'UTC') }}</div>
                <nz-switch *ngIf="!timeSlot.isEmptySlot() && timeSlot.hasFight()"
                           [(ngModel)]="timeSlot.isMainCard"
                           [nzCheckedChildren]="checkedTemplate"
                           [nzUnCheckedChildren]="unCheckedTemplate"
                           (ngModelChange)="onMainCardChange($event, timeSlot)"
                ></nz-switch>
                <ng-template #checkedTemplate>Main card</ng-template>
                <ng-template #unCheckedTemplate>Undercard</ng-template>
                <div class="choose-winner" *ngIf="timeSlot.isUnknownFightResult()">
                  <button nz-button (click)="openResultsModal(timeSlot)" nzType="primary">Set results</button>
                </div>
                <div class="move-slot" *ngIf="!timeSlot.isEmptySlot()">
                  <span *ngIf="!fightEvent.isFirstSlot(timeSlot)" (click)="swapSlots(timeSlot, idx, 'up')" class="move-slot-up"><i nz-icon nzType="up-circle" nzTheme="twotone" nzTooltipTitle="Move slot up" nz-tooltip></i></span>
                  <span *ngIf="!fightEvent.isLastSlot(timeSlot)" (click)="swapSlots(timeSlot, idx, 'down')" class="move-slot-down"><i nz-icon nzType="down-circle" nzTheme="twotone" nzTooltipPlacement="bottom" nzTooltipTitle="Move slot down" nz-tooltip></i></span>
                </div>
              </div>
            </div>
            <!-- RIGHT -->
            <div class="time-slot-item" nz-col nzXs="2" nzSm="4" nzMd="6" nzLg="8" nzXl="10">
              <ng-container *ngIf="!timeSlot.isEmptySlotTarget(); else emptySlotTarget">
                <arena-event-slot-card *ngIf="timeSlot.fight"
                                       [slot]="timeSlot"
                                       [fightEvent]="fightEvent"
                                       [fighter]="timeSlot.fight.target"
                                       [slotType]="slotTypes.Target"
                ></arena-event-slot-card>
              </ng-container>
              <ng-template #emptySlotTarget>
                <arena-event-slot-empty [slotType]="slotTypes.Target"
                                        (addCardClick)="onAddNewCardToSlot($event, timeSlot)"
                ></arena-event-slot-empty>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nz-content>
</nz-layout>
